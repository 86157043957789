import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/builderCarousel.css"
import styles from "../styles/styleElements"

import data from "../data/constructionOpenings.json"

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-8 absolute -top-16 right-8"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1727 12L8.22266 7.04999L9.63666 5.63599L16.0007 12L9.63666 18.364L8.22266 16.95L13.1727 12Z"
          fill="black"
        />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-8 absolute -top-16 right-20"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8273 12L15.7773 16.95L14.3633 18.364L7.99934 12L14.3633 5.63601L15.7773 7.05001L10.8273 12Z"
          fill="black"
        />
      </svg>
    </div>
  )
}

const ConstructionCarousel = ({ onButtonClick }) => {
  const settings = {
    dots: false,
    infinite: false,
    arrow: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchThreshold: 1000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div>
      <Slider {...settings}>
        {data.map((img, i) => (
          <div className={`w-full px-2 relative group`} key={i}>
            <div className="h-full mx-2 absolute top-0 right-0 left-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 z-10 flex justify-center items-center">
              <button
                className={`${styles.whiteOutlineButton}`}
                onClick={onButtonClick}
              >
                View openings
              </button>
            </div>
            <div
              className="h-80"
              alt="construction worker"
              style={{ background: `url(${img.src})`, backgroundSize: "cover" }}
            ></div>
            <div className="h-20 bg-gradient-to-t from-black absolute bottom-0 left-0 right-0 mx-2">
              <p className="text-white mt-8 ml-8">{img.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ConstructionCarousel
