import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/builderCarousel.css"

const BuilderCarousel = ({ data }) => {
  const settings = {
    dots: false,
    infinite: false,
    arrow: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div>
      <Slider {...settings}>
        {data.map((img, i) => (
          <div className={`w-full pr-4 relative h-80`} key={i}>
            <Img fluid={img.node.childImageSharp.fluid} className="h-full" />
            <div className="h-20 bg-gradient-to-t from-black absolute bottom-0 left-0 right-0 mr-4">
              <p className="text-white mt-8 ml-8">
                {img.node.childImageSharp.fluid.originalName.split(".")[0]}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default BuilderCarousel
